<template>
  <el-table :data="tableData" :cell-style="cellStyle" stripe border>
    <el-table-column
      :prop="id"
      label="场次"
      width="55"
      align="center"
      header-align="center"
      >
      <template slot-scope="scope">
        {{ scope.row.id }}
      </template>
    </el-table-column>
    <el-table-column
      prop="league"
      label="赛事"
      width="55"
      align="center"
      header-align="center"
    >
    </el-table-column>
    <el-table-column
      label="时间"
      width="110"
      align="center"
      header-align="center"
    >
      <template slot-scope="scope">
        {{ scope.row.date }} {{ scope.row.time }}
      </template>
    </el-table-column>
    <el-table-column
      label="主队VS客队"
      align="center"
      header-align="center"
      width="340"
    >
      <template slot-scope="scope">
        <div class="zhuke">
          <div class="left">
            <span>[{{ scope.row.z_paiming }}]</span>{{ scope.row.zteam }}
            <img :src="scope.row.zteam_logo_adress" alt="" />
          </div>
          <div class="center">-</div>
          <div class="right">
            <img :src="scope.row.kteam_logo_adress" alt="" />
            {{ scope.row.kteam }}<span>[{{ scope.row.k_paiming }}]</span>
          </div>
        </div>
      </template>
    </el-table-column>

    <el-table-column
      label="初盘"
      width="55"
      align="center"
      header-align="center"
    >
      初
    </el-table-column>

    <el-table-column
      prop="asia"
      label="亚盘"
      align="center"
      header-align="center"
    />


    <el-table-column
      align="center"
      header-align="center"
      label="分析"
      width="80"
    >
      <template slot-scope="scope">
        <el-button
          @click="clickFenxi(scope.row)"
          type="primary"
          size="mini"
          plain
          >分析</el-button
        >
      </template>
    </el-table-column>

    <el-table-column
      align="center"
      header-align="center"
      label="星币"
      width="60"
    >
      <template slot-scope="scope">
        <span v-if="scope.row.money" class="primary"
          >{{ scope.row.money }}星币</span
        >
        <span v-else>免费</span>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { showAll, show14, showGood } from "@/api/home.js";
import { verifyOrder } from "@/api/order";

import { getToken } from "@/utils/auth";
let timer = null;
export default {
  data() {
    return {
      tableData: []
    };
  },
  computed: {
    order() {
      let routeName = this.$route.name;
      let routeNameMap = {
        lsMain: "all_id",
        sfMain: "14_id",
        showNice: "good_id"
      };
      return routeNameMap[routeName];
    },
    isShowPayDialog() {
      return this.$store.state.isShowPayDialog;
    }
  },
  watch: {
    $route: {
      async handler() {
        let routeName = this.$route.name;
        let routeNameMap = {
          lsMain: showAll,
          sfMain: show14,
          showNice: showGood
        };
        let { data } = await routeNameMap[routeName]();
        this.tableData = data;
      },
      immediate: true
    },
    isShowPayDialog(val) {
      if (val === false) {
        clearInterval(timer);
      }
    }
  },

  methods: {
    cellStyle({ row, columnIndex }) {
      if (columnIndex === 1) {
        return { background: row.color, color: "#fff" };
      }
    },
    async clickFenxi(row) {

      if (!row.money) {
        this.toFenxi(row.play_id);
        return;
      }

      if (!getToken()) {
        this.$bus.$emit("openLogin");
        return;
      }

      let { data: flag } = await verifyOrder({ id: row.id });
      if (flag) {
        this.toFenxi(row.id);
      } else {
        this.$store.dispatch("showPayDialog", row);
        timer = setInterval(() => {
          this.setIntervalVerify(row.id);
        }, 3000);
      }
    },
    async setIntervalVerify(id) {
      let { data: flag } = await verifyOrder({ id });
      if (flag) {
        this.toFenxi(id);
      }
    },
    toFenxi(id) {
      clearInterval(timer);
      this.$store.dispatch("closePayDialog");
      let routeUrl = this.$router.resolve({
        path: "/fenxi/jibenmian",
        query: {
          id
        }
      });
      window.open(routeUrl.href, "_blank");
      return;
    }
  }
};
</script>

<style lang="scss" scoped>
.zhuke {
  display: flex;
  .center {
    display: inline-block;
    width: 30px;
    color: #999999;
  }
  .left,
  .right {
    display: inline-block;
    width: 150px;
    display: flex;
    align-items: center;
    img {
      margin: 0 5px;
    }
    span {
      color: #999999;
      padding: 2px;
    }
  }

  .left {
    justify-content: flex-end;
  }
  .right {
  }
}
.yanpan {
  .center {
    display: inline-block;
    width: 100px;
  }
}

.primary {
  color: #16a085;
}
</style>
