import request from "@/utils/request";

// 免费分析
export function showFree() {
  return request({
    url: "/ShowFree",
    method: "get"
  });
}

// 精品分析
export function showNice() {
  return request({
    url: "/ShowNice",
    method: "get"
  });
}

// 竞猜分析
export function lsMain() {
  return request({
    url: "/LsMain",
    method: "get"
  });
}

// 14场分析
export function sfMain() {
  return request({
    url: "/SfMain",
    method: "get"
  });
}

export function showAll() {
  return request({
    url: "/ShowAll",
    method: "get"
  });
}

export function show14() {
  return request({
    url: "/Show14",
    method: "get"
  });
}

export function showGood() {
  return request({
    url: "/ShowGood",
    method: "get"
  });
}
